<template>
    <v-toolbar
        class="w-100 d-flex align-center px-6 py-10 mt-7"
        content-class="w-100"
        color="white"
        elevation="0"
        rounded="lg"
    >
        <div class="d-flex justify-center flex-row w-100">
            <div class="d-flex justify-center flex-row mr-4">
                <v-btn fab class="mr-4" elevation="0">
                    <v-img src="/img/teacher/tutor-profile-sample.png" max-width="60" class="rounded-circle"></v-img>
                </v-btn>
                <div class="d-flex justify-center flex-column text-capitalize">
                    <span class="primary--text font-weight-bold font-size-14 text-capitalize">{{
                        teacherName
                    }}</span>
                    <span class="primary--text font-weight-light font-size-12">{{ userType }}</span>
                </div>
            </div>
            <v-divider inset vertical></v-divider>
            <div class="d-flex justify-center flex-column mx-4 align-start">
                <span class="primary--text font-weight-bold font-size-14">{{ schoolName }}</span>
                <span class="primary--text font-weight-light font-size-12">{{ countryName }}</span>
            </div>
            <v-divider inset vertical></v-divider>
            <div class="d-flex justify-space-between flex-row mx-4 align-center">
                <router-link to="/teacher/reports/dashboard" class="text-decoration-none">
                    <v-btn
                        small
                        color="#ebe8f4"
                        class="primary--text font-weight-regular font-size-12 mx-1 text-capitalize"
                        elevation="0"
                    >
                        <v-icon class="mr-2 font-size-16">mdi-account</v-icon>
                        {{ `${countCourses} ${$t('courses')}` }}
                    </v-btn>
                </router-link>

                <router-link to="/teacher/reports/dashboard" class="text-decoration-none">
                    <v-btn
                        small
                        color="#ebe8f4"
                        class="primary--text font-weight-regular font-size-12 mx-1 text-capitalize"
                        elevation="0"
                    >
                        <v-icon class="mr-2 font-size-16">mdi-clipboard-check</v-icon> {{ cStudent }}
                    </v-btn>
                </router-link>
            </div>
            <div class="d-flex justify-space-between flex-row mx-4 align-center">
                <v-menu
                    v-model="messagesMenu"
                    left
                    bottom
                    :close-on-content-click="false"
                    content-class="my-menu"
                    max-width="380"
                    nudge-top="-30"
                    nudge-right="140"
                    offset-y
                >
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" icon elevation="0" large>
                            <v-badge

                                :content="messages.length"
                                :value="messages.length"
                                overlap
                                color="error"
                            >
                                <v-img :src="messageImg" max-width="27.5" alt="msg icon"></v-img>
                            </v-badge>
                        </v-btn>
                    </template>
                    <v-card
                        elevation="0"
                        class="overflow-y-auto ma-4"
                        max-height="360"
                        min-width="360"
                    >
                        <v-list dense class="py-0 pr-2" v-if="messages.length">
                            <div v-for="(message, i) in mapContent" :key="i">
                                <v-list-item class="text-center" @click="openMessage(message)">
                                    <v-row align="center">
                                        <v-col cols="3" align="center">
                                            <strong class="secondary--text">
                                                {{ getRemaingTime(message.date) }}
                                            </strong>
                                        </v-col>
                                        <v-col cols="9" class="pa-1">
                                            <h5
                                                class="mb-2 text-left text-subtitle-2 primary--text text-capitalize font-weight-bold"
                                            >
                                                {{ $utils.upperFirstLetter(message.name) }}
                                            </h5>
                                            <p
                                                class="text-left text-caption mt-0 mb-1 primary--text"
                                            >
                                                {{ message.subject }}
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-list-item>
                                <v-divider class="my-2" />
                            </div>
                        </v-list>
                        <v-list dense class="py-0 pr-2" v-else>
                            <v-list-item class="text-center px-1">
                                <v-row align="center">
                                    <v-col cols="12" class="pa-1">
                                        <h5 class="mb-2 text-center text-h6 color-primary">
                                            {{ $t('not_message') }}
                                        </h5>
                                    </v-col>
                                </v-row>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>

                <!--                <v-menu-->
                <!--                    v-model="notificationsMenu"-->
                <!--                    left-->
                <!--                    bottom-->
                <!--                    :close-on-content-click="false"-->
                <!--                    content-class="my-menu"-->
                <!--                    max-width="380"-->
                <!--                    nudge-top="-30"-->
                <!--                    nudge-right="140"-->
                <!--                    offset-y-->
                <!--                >-->
                <!--                    <template v-slot:activator="{ on }">-->
                <!--                        <v-btn v-on="on" icon elevation="0" class="mx-1 mx-md-3" large>-->
                <!--                            <v-img-->
                <!--                                :src="notificationImg"-->
                <!--                                max-width="30"-->
                <!--                                alt="msg notifications"-->
                <!--                            ></v-img>-->
                <!--                        </v-btn>-->
                <!--                    </template>-->
                <!--                    <v-card elevation="0" class="overflow-y-auto ma-4" max-height="360">-->
                <!--                        <v-list dense class="py-0 pr-2">-->
                <!--                            <div v-for="(noti, i) in notifications" :key="i">-->
                <!--                                <v-list-item-->
                <!--                                    class="text-center px-1"-->
                <!--                                    v-if="noti.show"-->
                <!--                                    @click="redirectTo(noti.to)"-->
                <!--                                >-->
                <!--                                    <v-row align="center">-->
                <!--                                        <v-col cols="2" align="center">-->
                <!--                                            <v-img-->
                <!--                                                :src="noti.icon"-->
                <!--                                                max-height="40"-->
                <!--                                                max-width="40"-->
                <!--                                            ></v-img>-->
                <!--                                        </v-col>-->
                <!--                                        <v-col cols="10" class="pa-1">-->
                <!--                                            <h5-->
                <!--                                                class="mb-2 text-left text-subtitle-2 color-primary font-weight-bold"-->
                <!--                                            >-->
                <!--                                                {{ noti.title }}-->
                <!--                                            </h5>-->
                <!--                                            <p-->
                <!--                                                class="text-left text-caption mt-0 mb-1 color-primary"-->
                <!--                                            >-->
                <!--                                                {{ noti.desc }}-->
                <!--                                            </p>-->
                <!--                                        </v-col>-->
                <!--                                    </v-row>-->
                <!--                                </v-list-item>-->
                <!--                                <v-divider class="my-2" v-if="noti.show" />-->
                <!--                            </div>-->
                <!--                        </v-list>-->
                <!--                    </v-card>-->
                <!--                </v-menu>-->
                <!--                <v-btn-->
                <!--                    small-->
                <!--                    text-->
                <!--                    fab-->
                <!--                    :class="`primary&#45;&#45;text font-weight-regular font-size-12 mx-1 text-capitalize-->
                <!--               d-flex justify-center align-center`"-->
                <!--                    elevation="0"-->
                <!--                >-->
                <!--                    <v-img :src="messageImg" max-width="27.5" alt="msg icon"></v-img>-->
                <!--                </v-btn>-->
                <v-btn
                    small
                    text
                    fab
                    :class="`primary--text font-weight-regular font-size-12 mx-1 text-capitalize
                               d-flex justify-center align-center`"
                    elevation="0"
                >
                    <v-img :src="notificationImg" max-width="30" alt="msg notifications"></v-img>
                </v-btn>
            </div>
            <v-btn-toggle
                class="align-center ml-auto"
                v-model="selectedLang"
                mandatory
                @change="$i18n.locale = $event"
                background-color="transparent"
                active-class="active-lang-btn"
            >
                <v-btn
                    class="lang-btn"
                    v-for="lang in langs"
                    icon
                    :value="lang.key"
                    :key="lang.key"
                    height="32px"
                    width="100px"
                >
                    <!-- <v-img :src="lang.icon" max-width="100" alt="msg icon"></v-img> -->
                    <span class="lang-label">{{ lang.label.toUpperCase() }}</span>
                </v-btn>
            </v-btn-toggle>
            <div class="d-flex justify-space-between align-center ml-8">
                <v-img :src="logoImg" max-width="100" alt="msg icon"></v-img>
            </div>
        </div>
        <MessageModal
            :dialog="showMessage"
            v-if="currentMessage"
            :message="currentMessage"
            @close-modal="closeMessageModal"
        ></MessageModal>
    </v-toolbar>
</template>

<script>
import { mapState } from 'vuex'
import messageImg from '@/assets/img/icons/message.svg'
import notificationImg from '@/assets/img/icons/notification.svg'
import logoImg from '@/assets/img/brand/logo.svg'
import spainFlag from '@/assets/img/icons/spainflag.svg'
import usaFlag from '@/assets/img/icons/usaflag.svg'
import MessageModal from '../resources/modals/MessageModal'
import { COURSE_REPORT_TYPE, STUDENT_REPORT_TYPE } from '../resources/Reports.vue'

export default {
    name: 'Topbar',
    components: {MessageModal},
    data() {
        return {
            messageImg,
            notificationImg,
            logoImg,
            notificationsMenu: false,
            messagesMenu: false,
            currentMessage: null,
            showMessage: false,
            COURSE_REPORT_TYPE,
            STUDENT_REPORT_TYPE,
            selectedLang: this.$i18n.locale,
            langs: [
                {
                    icon: spainFlag,
                    key: 'es',
                    label: 'Español',
                },
                {
                    icon: usaFlag,
                    key: 'en',
                    label: 'English',
                },
            ],
        }
    },
    methods: {
        capitalizeWord(name) {
            if (typeof name !== 'string') return ''
            return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
        },
        getRemaingTime(date = '') {
            return this.$moment(date).fromNow()
        },
        openMessage(data) {
            this.currentMessage = data
            this.messagesMenu = false
            this.showMessage = true
        },
        closeMessageModal() {
            this.currentMessage = null
            this.showMessage = false
        },
        closeMessage() {
            this.showMessage = false
            // MessageService.setReadMessages({ idList: [this.currentMessage.id] })
            // const found = this.messages.find((i) => i.message.id === this.currentMessage.id)
            // this.messages.splice(this.messages.indexOf(found), 1)
            this.currentMessage = null
        },
        redirectTo(to = '') {
            this.notificationsMenu = !this.notificationsMenu
            if (to) this.$router.push(to)
        },
    },
    computed: {
        ...mapState({
            user: (state) => state.userData.user,
            countStudent: (state) => state.userData.countStudent,
            courses: (state) => state.userData.courses,
            messages: (state) => state.messages,
            notifications: (state) => state.notifications,
        }),
        teacherName() {
            return `${this.capitalizeWord(this.user.firstname.split(' ')[0])} ${this.capitalizeWord(
                this.user.lastname.split(' ')[0]
            )}`
        },
        userType() {
            return this.$t(this.user.userType)
        },
        countCourses() {
            return `${this.courses.length}`
        },
        schoolName() {
            return `${this.user.school.name}`
        },
        countryName() {
            return `${this.user.school.baseCountryName}`
        },
        cStudent() {
            return `${this.countStudent} ${this.$t('students')}`
        },
        mapContent() {
            return this.messages.map((i) => {
                return {
                    id: i.message.id,
                    name: i.userFrom
                        ? this.$utils.getFullName(i.userFrom)
                        : this.$utils.getFullName(i.message.fromUser),
                    userId: i.userFrom?.id,
                    avatar: i.userFrom?.avatar,
                    subject: `${i.message.subject}`,
                    body: `${i.message.message}`,
                    date: i.message.issueDate,
                    message: i.message.message,
                }
            })
        },
    },
}
</script>

<style lang="css" scoped>

.my-menu {
    contain: initial;
    overflow: visible;
}

.my-menu:after {
    /* This is the shadow triangle */
    position: absolute;
    top: -22px;
    right: 145px;
    content: '';
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 22px solid rgba(0, 0, 0, 0.03);
    z-index: 1;
}
.my-menu::before {
    position: absolute;
    content: '';
    top: 0;
    right: 150px;
    transform: translateY(-100%);
    width: 15px;
    height: 20px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid #fff;
    z-index: 2;
}

.v-list-item,
.v-list-item:before {
    border-radius: 10px;
}

.v-list-item:hover {
    background-color: #f3efff;
}

.v-menu__content {
    background-color: #ffff;
    border-radius: 10px;
}

.lang-label {
    font-family: 'Space Grotesk';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.04em;
    color: #616161;
}

.lang-btn {
    border: solid #CFCFCF;
    border-radius: 6px !important;
    border-width: 2px !important;
    margin-left: 10px;
}

.active-lang-btn {
    background: #8A76C8;
    border-width: 0 !important;
}

.active-lang-btn .lang-label {
    color: #FFFFFF;
}
</style>
