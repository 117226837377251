<template>
    <div>
        <!-- <v-row class="style-ad" v-show="ad" /> -->
        <Sidebar />
        <div class="ml-16 d-flex align-center flex-column">
            <v-container class="mx-10 d-flex align-center flex-column">
                <Topbar />
                <router-view />
                <!-- <v-row class="previous-version">
                    {{ $t('ad_text[0]') }}
                    <a class="ml-1 yellow-text" :href="redirect">
                        {{ $t('ad_text[1]') }}
                    </a>
                </v-row> -->
            </v-container>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Sidebar from '../components/layout/Sidebar'
import Topbar from '../components/layout/Topbar'

export default {
    name: 'br-index',
    components: {
        Sidebar,
        Topbar,
    },
    computed: {
        ...mapState({
            token: (state) => state.token,
        }),
    },
    data() {
        return {
            ad: true,
            redirect: '',
        }
    },
    mounted() {
        this.redirect = `${process.env.VUE_APP_BEEREADERS_TEACHERS_OLD}/?token=${this.token}`
    },
}
</script>

<style type="css" scoped>
.previous-version{
    padding: 20px 5px;
}
.yellow-text {
    color: #FAB215;
    text-decoration: none;
}
</style>
