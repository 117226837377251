<template>
    <v-navigation-drawer
        :class="ad? 'padding-ad' : ''"
        color="primary"
        permanent
        v-model="drawer"
        fixed
        style="width: 70px"
    >
        <v-list dense nav class="px-0 pl-2 d-flex flex-column justify-center align-end h-100">
            <v-tooltip right v-for="(item, index) in routes" :key="index">
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                        :active-class="'router-link-active'"
                        :to="item.link"
                        @click="trackSection(item)"
                        :class="`d-flex justify-center align-center border-radius-right-unset
                    list-item-max-height my-2
                    ${item.isFirst ? ' mb-auto ' : ''} ${item.isLast ? ' mt-auto ' : ''}`"

                    >

                        <v-list-item-action v-bind="attrs" v-on="on">
                            <div :class="`link-icon ${item.icon}`"></div>
                        </v-list-item-action>
                    </v-list-item>
                </template>
                <span class="white--text">{{ item.title }}</span>
            </v-tooltip>
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                        :active-class="'router-link-active'"
                        @click="logout"
                        :class="`d-flex justify-center align-center border-radius-right-unset list-item-max-height my-2 mt-auto`"
                        data-cy="library-button"
                    >
                        <v-list-item-action v-bind="attrs" v-on="on">
                            <div :class="`link-icon logout-img`"></div>
                        </v-list-item-action>
                    </v-list-item>
                </template>
                <span class="white--text">{{ $t('logout') }}</span>
            </v-tooltip>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import accessHistory from '@/services/accessHistory'
import { mapState, mapActions } from 'vuex'

import AnalyticEventsMixin from '@/mixins/analyticEvents' //eslint-disable-line
import { AnalyticEventTypes } from '../../constants/AnalyticEventTypes'

export default {
    name: 'Sidebar',
    props: {
        ad: {
            type: Boolean,
            default: false,
        },
    },
    mixins: [AnalyticEventsMixin],
    data() {
        return {
            drawer: false,
        }
    },
    methods: {
        ...mapActions(['clearSession']),
        logout() {
            this.$modal.confirm(this.$t('to_confirm'), this.$t('confirm_logout_msg'), async () => {
                await accessHistory.setLogout({ userId: this.userId })
                    .then((response) => console.info(response))
                    .catch((error) => console.error(error));
                this.clearSession().then(() => {
                    setTimeout(() => {
                        window.location.href = process.env.VUE_APP_BEEREADERS_LOGIN_URL
                    }, 100)
                })
            })
        },
        trackSection(route) {
            this.createAnalyticEvent(
              AnalyticEventTypes.GoToSideBarSection, { sideBarSection: route.title }
            )
        }
    },
    computed: {
        ...mapState({
            userId: (state) => state.userData.user.id,
        }),
        currentRoute() {
            return this.$router.currentRoute.path
        },
        routes() {
            return [
                {
                    title: this.$t('quick_stats_link'),
                    icon: 'quick-stats-img',
                    link: '/teacher/quick-stats',
                    isLast: false,
                    isFirst: false,
                },
                {
                    title: this.$t('library_link'),
                    icon: 'library-img',
                    link: '/teacher/library',
                    isLast: false,
                    isFirst: false,
                },
                {
                    title: this.$t('resource_room_link'),
                    icon: 'resources-img',
                    link: '/teacher/resources',
                    isLast: false,
                    isFirst: false,
                },
                {
                    title: this.$t('reports.dashboard.title'),
                    icon: 'reports-img',
                    link: '/teacher/reports/dashboard',
                    isLast: false,
                    isFirst: false,
                },
                {
                    title: this.$t('diagnoses.titles.evaluations'),
                    icon: 'diagnostics-img',
                    link: '/teacher/diagnoses',
                    isLast: false,
                    isFirst: false,
                },
                {
                    title: this.$t('evaluate.fluency'),
                    icon: 'fluency-img',
                    link: '/teacher/evaluations',
                    isLast: false,
                    isFirst: false,
                },
            ]
        },
    },
}
</script>

<style lang="css" scoped>
.border-radius-right-unset {
    border-radius: 1.5vh 0 0 1.5vh !important;
    border-right: #f6f6f6;
    z-index: 1;
}

.list-item-max-height {
    max-height: 5vh;
    /* //max-width: 7.5vh; */
}

.v-list .v-list-item--active {
    background: #f6f6f6 !important;
    color: #f6f6f6 !important;
}

.router-link-active i {
    color: #514773 !important;
}

.link-icon {
    width: 5vh;
    height: 5vh;
    background-position: center center;
}

.home-img {
    background-image: url(../../assets/img/icons/home-01.svg);
}

.router-link-active .home-img {
    background-image: url(../../assets/img/icons/home-02.svg) !important;
}

.quick-stats-img {
    background-image: url(../../assets/img/icons/quickstats-01.svg);
}

.router-link-active .quick-stats-img {
    background-image: url(../../assets/img/icons/quickstats-02.svg) !important;
}

.report-skill-level-img {
    background-image: url(../../assets/img/icons/skills-01.svg);
}

.router-link-active .report-skill-level-img {
    background-image: url(../../assets/img/icons/skills-02.svg);
}

.library-img {
    background-image: url(../../assets/img/icons/library-01.svg);
}

.router-link-active .library-img {
    background-image: url(../../assets/img/icons/library-02.svg);
}

.sessions-img {
    background-image: url(../../assets/img/icons/sessions_data-01.svg);
}

.router-link-active .sessions-img {
    background-image: url(../../assets/img/icons/sessions_data-02.svg);
}

.resources-img {
    background-image: url(../../assets/img/icons/resourceroom-01.svg);
}

.router-link-active .resources-img {
    background-image: url(../../assets/img/icons/resourceroom-02.svg);
}

.reports-img {
    background-image: url(../../assets/img/icons/reports/reports-icon-2.svg);
}

.router-link-active .reports-img {
    background-image: url(../../assets/img/icons/reports/reports-icon.svg);
}
.diagnostics-img {
    background-image: url(../../assets/img/icons/diagnostics/diagnostics-icon.svg);
}
.router-link-active .diagnostics-img {
    background-image: url(../../assets/img/icons/diagnostics/diagnostics-icon-selected.svg);
}

.fluency-img {
    background-image: url(../../assets/img/icons/fluency/fluency-mic.svg);
}
.router-link-active .fluency-img {
    background-image: url(../../assets/img/icons/fluency/fluency-mic-active.svg);
}

.logout-img {
    background-image: url(../../assets/img/icons/logout-01.svg);
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: unset !important;
}
.padding-ad {
    margin-top: 50px;
    padding-bottom: 50px;
}
</style>
